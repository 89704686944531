<template>
<library-item 
    :class="container_class" 
    :entity_type="entity_type" 
    :get_route="get_route" 
    :post_route="post_route" 
    :patch_toggle_route="patch_toggle_route" 
    :main_title="main_title" 
    :panel_title="panel_title" 
    :add_button_text="add_button_text" 
    :add_item_title="add_item_title" 
    :edit_item_title="edit_item_title" 
    :delete_item_title="delete_item_title" 
    :deleting_item_message="deleting_item_message" 
    :placeholder_find_item="placeholder_find_item" />
</template>

<script>
import _ from 'lodash'
import LibraryItem from '../../template-parts/LibraryItem'

export default {
    name: 'TrainingLevels',
    components: {
        'library-item': LibraryItem
    },
    computed: {
        container_class: function () {
            return 'training-levels'
        },
        entity_type: function () {
            return 'training_level'
        },
        panel_title: function () {
            return 'training_levels'
        },
        get_route: function () {
            return baseUrl + 'training_levels'
        },
        post_route: function () {
            return baseUrl + 'training_levels'
        },
        patch_toggle_route: function () {
            return baseUrl + 'training_levels'
        },
        main_title: function () {
            return this.translate('training_levels')
        },
        add_button_text: function () {
            return this.translate('add_training_level')
        },
        add_item_title: function () {
            return this.translate('add_training_level')
        },
        edit_item_title: function () {
            return this.translate('edit_training_level')
        },
        delete_item_title: function () {
            return this.translate('delete_training_level')
        },
        deleting_item_message: function () {
            return this.translate('deleting_training_level')
        },
        placeholder_find_item: function () {
            return this.translate('find_training_level')
        },
    },
    data: function () {
        return {}
    },
}
</script>
